import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import palette from '../theme/palette';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { getUsers, updateUser } from '../helper/backend_helper';
import appConfig from '../config';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'company', label: 'Email', alignRight: false },
  // { id: 'role', label: 'Mobile Number', alignRight: false },
  { id: 'isVerified', label: 'Number Of Users', alignRight: false },
  { id: 'isVerified', label: 'Number Of Videos', alignRight: false },
  { id: 'isVerified', label: 'Last login', alignRight: false },
  { id: 'CreatedAt', label: 'Created At', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: true },
  { id: 'Last login', label: 'Last Suspended', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BusinessUserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getUsers('business', '');
        console.log(response.data);
        if (isMounted) {
          setUsers(response.data.data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  const handleblock = async (user) => {
    await Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Suspend it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateUser(user?._id, { isActive: false, lastSuspended: new Date() });
        Swal.fire({
          title: 'Suspended!',
          text: 'User has been Suspended.',
          icon: 'success',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const handleActive = async (user) => {
    await Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Active it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateUser(user?._id, { isActive: true });
        Swal.fire({
          title: 'Activated!',
          text: 'User has been activated.',
          icon: 'success',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> User | Cyberyard </title>
      </Helmet>

      {/* <Container maxWidth=""> */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Business Users
        </Typography>
        <Link to="/dashboard/business-user/create-business-user">
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            sx={{
              backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
              color: 'black',
            }}
          >
            New Business
          </Button>
        </Link>
      </Stack>

      <Card>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={USERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { _id, QRCodeImage, name, email, phoneNumber, isActive, directory } = row;
                  if (row.role.name !== 'admin') {
                    return (
                      <TableRow hover key={_id}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="left">{row?.users}</TableCell>
                        <TableCell align="left">{row?.videos}</TableCell>
                        <TableCell align="left">
                          {row.lastLogin && new Date(row.lastLogin).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="left">
                          {/* <Avatar alt={name} src={`${appConfig.FILe_URL}/${QRCodeImage}`} variant="square" /> */}
                          {new Date(row.createdAt).toLocaleDateString()}
                        </TableCell>

                        {/* <TableCell align="left">
                            {
                              <Label color={(isActive && 'success') || 'error'}>
                                {sentenceCase(isActive ? 'Active' : 'Suspended')}
                              </Label>
                            }
                          </TableCell> */}

                        <TableCell align="right" width={500}>
                          <div style={{ display: 'flex', gap: 30, fontSize: 13 }}>
                            <Link
                              to="/dashboard/business-user/edit-business-user"
                              state={{ user: row }}
                              style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'gray' }}
                            >
                              <Iconify icon={'eva:edit-outline'} sx={{ mr: 1 }} />
                              Edit
                            </Link>
                            <Link
                              to="/dashboard/user"
                              state={{ user: row }}
                              style={{
                                textDecoration: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                color: 'green',
                              }}
                            >
                              <Iconify icon={'eva:people-outline'} sx={{ mr: 1 }} />
                              See Users
                            </Link>
                            <Link
                              to="/dashboard/category"
                              state={{ user: row }}
                              style={{
                                textDecoration: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                color: 'blue',
                              }}
                            >
                              <Iconify icon={'eva:film-outline'} sx={{ mr: 1 }} />
                              See Videos
                            </Link>

                            {!isActive ? (
                              <Link
                                onClick={() => handleActive(row)}
                                style={{
                                  textDecoration: 'none',
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: 'red',
                                }}
                              >
                                <Iconify icon={'eva:close-circle-outline'} sx={{ mr: 1 }} />
                                Suspended
                              </Link>
                            ) : (
                              <Link
                                onClick={() => handleblock(row)}
                                style={{
                                  textDecoration: 'none',
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: 'green',
                                }}
                              >
                                <Iconify icon={'eva:checkmark-circle-2-outline'} sx={{ mr: 1 }} />
                                Active
                              </Link>
                            )}
                          </div>

                          {/* <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton> */}
                        </TableCell>
                        <TableCell align="left">
                          {row?.lastSuspended && new Date(row.lastSuspended).toLocaleDateString()}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
                {loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5}>
                      <CircularProgress size="20px" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      {/* </Container> */}

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Link to="/dashboard/user/edit-user">
            <Iconify icon={'eva:edit-outline'} sx={{ mr: 2 }} />
            Edit
          </Link>
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Block
        </MenuItem>
      </Popover>
    </>
  );
}
