import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Button, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';

// components
import Swal from 'sweetalert2';
import palette from '../../../theme/palette';
import { createUser, postJwtLogin, uploadFile } from '../../../helper/backend_helper';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function CreateBusinessUserFrom() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState(null);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    adminFirstName: Yup.string().required('First name name is required'),
    adminLastName: Yup.string().required('Last name is required'),
    adminEmail: Yup.string().email('Invalid email').required('Email is required'),
    adminPhoneNumber: Yup.string().required('Phone Number is required'),
    name: Yup.string().required('Business name is required'),
    phoneNumber: Yup.string().required('Phone Number name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    users: Yup.number().positive('Number of users must be greater than 0').required('Number of users is required'),
  });

  const handeLogin = async (values) => {
    if (!values?.email || !values?.password || !values?.name) {
      return null;
    }

    try {
      setLoading(true);

      // if (image) {
      //   const uploadFileheaders = {
      //     headers: {
      //       'content-type': 'multipart/form-data',
      //     },
      //   };

      //   const formData = new FormData();
      //   formData.append('file', image);
      //   const fileResponse = await uploadFile(formData, uploadFileheaders);
      //   values.QRCodeImage = fileResponse.data.file.filename;
      // }
      const response = await createUser({ ...values, userRole: 'business' });

      setLoading(false);
      navigate('/dashboard/business-user', { replace: true });
    } catch (error) {
      setLoading(false);
      if (error?.response?.status >= 400) {
        return Swal.fire({
          position: 'center',
          icon: 'error',
          title: error.response.data.errors[0].detail,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      adminFirstName: '',
      adminLastName: '',
      adminEmail: '',
      adminPhoneNumber: '',
      name: '',
      email: '',
      phoneNumber: '',
      password: '',
      users: 0,
      allowAutoLogout: false,
    },
    validationSchema,
    onSubmit: (values) => handeLogin(values),
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <Stack gap={3} direction={{ md: 'row', sm: 'column' }}>
          <TextField
            fullWidth
            label="Admin First Name"
            name="adminFirstName"
            value={formik.values.adminFirstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.adminFirstName && !!formik.errors.adminFirstName}
            helperText={formik.touched.adminFirstName && formik.errors.adminFirstName}
          />
          <TextField
            fullWidth
            label="Admin Last Name"
            name="adminLastName"
            value={formik.values.adminLastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.adminLastName && !!formik.errors.adminLastName}
            helperText={formik.touched.adminLastName && formik.errors.adminLastName}
          />
        </Stack>

        <TextField
          label="Admin Email"
          name="adminEmail"
          value={formik.values.adminEmail}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.adminEmail && !!formik.errors.adminEmail}
          helperText={formik.touched.adminEmail && formik.errors.adminEmail}
        />
        <TextField
          label="Admin Phone Number"
          name="adminPhoneNumber"
          value={formik.values.adminPhoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.adminPhoneNumber && !!formik.errors.adminPhoneNumber}
          helperText={formik.touched.adminPhoneNumber && formik.errors.adminPhoneNumber}
        />

        <Stack gap={3} direction={{ md: 'row', sm: 'column' }}>
          <TextField
            fullWidth
            label="Business Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
          />

          <TextField
            fullWidth
            label="Business Phone Number"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          />
        </Stack>

        <TextField
          fullWidth
          label="Business Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          name="password"
          label="Business Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && !!formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
        />

        <TextField
          label="Number of users"
          name="users"
          value={formik.values.users}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.users && !!formik.errors.users}
          helperText={formik.touched.users && formik.errors.users}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <FormControlLabel
        control={<Checkbox checked={formik.values.allowAutoLogout} />}
        label="Allow automatic logout users after 12 hours"
        name="allowAutoLogout"
        onChange={formik.handleChange}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      {image && (
        <img
          src={URL.createObjectURL(image)}
          className="cover"
          style={{
            objectFit: 'cover',
            width: '150px',
            height: '150px',
            borderRadius: 10,
          }}
          alt="QR CODE"
        />
      )}
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      {/* <Button variant="outlined" size="large" component="label" sx={{ width: 200, color: 'black' }}>
        UPLOAD QR IMAGE
        <input type="file" hidden accept="image/*" value="" onChange={(e) => setImage(e.target.files[0] || null)} />
      </Button>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} /> */}

      <LoadingButton
        sx={{
          backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
          color: 'black',
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handeLogin}
        loading={loading}
      >
        Create
      </LoadingButton>
    </form>
  );
}
