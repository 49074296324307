// component
import { FaChalkboard } from 'react-icons/fa';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { LuUserSquare } from 'react-icons/lu';
import { GrGallery } from 'react-icons/gr';
import { TfiAnnouncement } from 'react-icons/tfi';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { CiLogout } from 'react-icons/ci';

import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`http://93.188.167.72/moodlift-admin/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const user = localStorage.getItem('cyberyard');
const role = user && JSON.parse(user)?.user?.role?.name;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <MdOutlineSpaceDashboard />,
  },
  {
    title: 'Business Users',
    path: '/dashboard/business-user',
    icon: <LuUserSquare />,
  },
  // {
  //   title: 'users',
  //   path: '/dashboard/user',
  //   icon: <LuUserSquare />,
  // },
  {
    title: 'Website',
    path: '/dashboard/link',
    icon: <GrGallery />,
  },
  // {
  //   title: 'Announcement',
  //   path: '/dashboard/guide',
  //   icon: <TfiAnnouncement />,
  // },
  // {
  //   title: 'content',
  //   path: '/dashboard/content',
  //   icon: icon('ic_cart'),
  //   children: [
  //     {
  //       title: 'Stories',
  //       path: '/dashboard/content',
  //       icon: icon('ic_cart'),
  //     },
  //   ],
  // },
  // {
  //   title: 'Noticiations',
  //   path: '/dashboard/analytics',
  //   icon: <IoMdNotificationsOutline />,
  // },
  // {
  //   title: 'Subscription',
  //   path: '/dashboard/subscription',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'Notifications',
  //   path: '/dashboard/notification',
  //   icon: icon('ic_notification_chat'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Logout',
  //   path: '/login',
  //   icon: <CiLogout />,
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

const navConfigBusiness = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <MdOutlineSpaceDashboard />,
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: <LuUserSquare />,
  },
  {
    title: 'Videos',
    path: '/dashboard/category',
    icon: <GrGallery />,
  },
];

export default role === 'admin' ? navConfig : navConfigBusiness;
