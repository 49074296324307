import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Box, Collapse, List, ListItemText } from '@mui/material';
//
import { Icon } from '@iconify/react';
import { CiLogout } from 'react-icons/ci';

import palette from '../../theme/palette';
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await localStorage.removeItem('cyberyard');
    navigate('/login', { replace: true });
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}

        <StyledNavItem
          onClick={() => handleLogout()}
          sx={{
            '&.active': {
              color: 'text.primary',

              backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>
            <CiLogout />
          </StyledNavItemIcon>

          <ListItemText disableTypography primary="Logout" />
        </StyledNavItem>
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, children } = item;
  // const isActiveRoot = active(item.path);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  if (children) {
    return (
      <StyledNavItem
        onClick={handleOpen}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {info && info}
        {children && (
          <>
            <Icon icon={open ? 'mdi:chevron-up' : 'mdi:chevron-right'} style={{ width: 16, height: 16, ml: 1 }} />

            <Collapse in={open} timeout="auto" unmountOnExit>
              {children.map((item) => {
                const { title, path, icon } = item;

                return (
                  <List component="div" disablePadding>
                    <StyledNavItem
                      component={RouterLink}
                      to={path}
                      sx={{
                        '&.active': {
                          color: 'text.primary',
                          bgcolor: 'action.selected',
                          fontWeight: 'fontWeightBold',
                        },
                      }}
                    >
                      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

                      <ListItemText disableTypography primary={title} />
                    </StyledNavItem>
                  </List>
                );
              })}
            </Collapse>
          </>
        )}
      </StyledNavItem>
    );
  }

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          // bgcolor: 'action.selected',
          backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
