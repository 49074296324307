import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';

// components
import Swal from 'sweetalert2';
import palette from '../../../theme/palette';
import { postJwtLogin } from '../../../helper/backend_helper';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userRole, setUserRole] = useState('admin');

  const handleChange = (event) => {
    setUserRole(event.target.value);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  });

  const handeLogin = async (values) => {
    try {
      setLoading(true);
      const response = await postJwtLogin({ ...values, userRole });
      const role = response.data.data.user.role.name;
      if (role !== 'admin' && role !== 'business') {
        setLoading(false);

        return await Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'You have entered an invalid email address or password',
          showConfirmButton: false,
          // timer: 1500,
        });
      }
      localStorage.setItem('cyberyard', JSON.stringify(response.data.data));
      setLoading(false);
      navigate('/dashboard', { replace: true });
      window.location.reload();
    } catch (error) {
      setLoading(false);
      if (error?.response?.status >= 400) {
        return Swal.fire({
          position: 'center',
          icon: 'error',
          title: error.response.data.errors[0].detail,
          showConfirmButton: false,
          // timer: 1500,
        });
      }
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => handeLogin(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          label="Email address"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && !!formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={userRole}
        onChange={handleChange}
      >
        <FormControlLabel value="admin" control={<Radio />} label="Admin" />
        <FormControlLabel value="business" control={<Radio />} label="Business" />
      </RadioGroup>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <LoadingButton
        sx={{
          backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
          color: 'black',
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handeLogin}
        loading={loading}
      >
        Login
      </LoadingButton>
    </form>
  );
}
