import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.fullName(),
  company: faker.internet.email(),
  isVerified: faker.phone.number(),
  status: sample(['active', 'banned']),
  role: faker.phone.number(),
}));

export default users;
