import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// Login Method
export const postJwtLogin = (data, headers) => api.create(url.POST_LOGIN, data, headers);

export const uploadFile = (data, headers) => api.create(url.UPLOAD_FILE, data, headers);
export const deleteFile = (data, headers) => api.create(url.DELETE_FILE, data, headers);

export const getDashboard = (role, businessId) => api.get(`${url.GET_DASHBOARD}/${role}`, { businessId });

export const createUser = (data, headers) => api.create(url.CREATE_USERS, data, headers);
export const changePasswordUser = (data, headers) => api.create(url.CHANGE_PASSWORD, data, headers);
export const updateUser = (id, data, headers) => api.create(`${url.UPDATE_USER}/${id}`, data, headers);
export const deleteUser = (id, data, headers) => api.create(`${url.DELETE_USER}/${id}`, data, headers);
export const updateBusiness = (id, data, headers) => api.create(`${url.UPDATE_BUSINESS}/${id}`, data, headers);
export const updateLogout = (id, data, headers) => api.create(`${url.UPDATE_LOGOUT}/${id}`, data, headers);
export const getUser = (userId) => api.get(`${url.GET_USER}/${userId}`);
export const getUsers = (role, businessId) => api.get(`${url.GET_USERS}/${role}`, { businessId });

export const createAlbum = (data, headers) => api.create(url.CREATE_ALBUM, data, headers);
export const updateAlbum = (data, headers) => api.create(url.UPDATE_ALBUM, data, headers);
export const getAlbums = () => api.get(url.GET_ALBUMS);
export const checkExitingVideos = (data, headers) => api.create(url.GET_EXISTING_VIDEOS, data, headers);

export const createVideo = (data, headers) => api.create(url.CREATE_VIDEO, data, headers);
export const getVideos = (businessId) => api.get(`${url.GET_VIDEO}/${businessId}`);

export const deleteVideo = (data, headers) => api.create(url.DELETE_VIDEO, data, headers);

export const getCategories = () => api.get(url.GET_CATEGORIES);

export const createLink = (data, headers) => api.create(url.CREATE_LINK, data, headers);
export const getLink = () => api.get(url.GET_LINK);
