import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import CategoryPage from './pages/CategoryPage';
import SubscriptionsPage from './pages/SubscriptionsPage';
import AnalyticsPage from './pages/AnalyticsPage';
import DailyGuidePage from './pages/DailyGuidePage';
import NotificationPage from './pages/NotificationPage';
import DashboardAppPage from './pages/DashboardAppPage';
import SubCategoryPage from './pages/SubCategoryPage';
import CreateUserPage from './pages/CreateUserPage';
import CreateAlbumPage from './pages/CreateAlbumPage';
import EditUserPage from './pages/EditUserPage';
import EditAlbumPage from './pages/EditAlbumPage';
import BusinessUserPage from './pages/BusinessUserPage';
import CreateBusinessUserPage from './pages/CreateBusinessUserPage';
import EditBusinessUserPage from './pages/EditBusinessUserPage';
import CreateLinkForm from './sections/@dashboard/link/CreateLinkForm';
import CreateLinkPage from './pages/CreateLinkPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'business-user', element: <BusinessUserPage /> },
        { path: 'business-user/create-business-user', element: <CreateBusinessUserPage /> },
        { path: 'business-user/edit-business-user', element: <EditBusinessUserPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'user/create-user', element: <CreateUserPage /> },
        { path: 'user/edit-user', element: <EditUserPage /> },
        { path: 'category', element: <CategoryPage /> },
        {
          path: 'category/create-album',
          element: <CreateAlbumPage />,
        },
        {
          path: 'category/edit-album',
          element: <EditAlbumPage />,
        },
        {
          path: 'link',
          element: <CreateLinkPage />,
        },
        {
          path: 'category/sub-category',
          element: <SubCategoryPage />,
        },
        { path: 'subscription', element: <SubscriptionsPage /> },
        { path: 'analytics', element: <AnalyticsPage /> },
        { path: 'guide', element: <DailyGuidePage /> },
        { path: 'notification', element: <NotificationPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
