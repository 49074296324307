import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
// components
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { getDashboard, getLink, updateLogout, updateUser } from '../helper/backend_helper';

// ----------------------------------------------------------------------
const user = localStorage.getItem('cyberyard');
const userObject = user && JSON.parse(user)?.user;
const role = user && JSON.parse(user)?.user?.role?.name;

export default function DashboardAppPage() {
  const theme = useTheme();

  const [dashboard, setDashboard] = useState({ business: 0, users: 0, videos: 0 });
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState('');
  const [allowLogout, setAllowLogout] = useState(false);
  const [autoLogout, setAutoLogout] = useState(false);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getDashboard(role, role === 'business' ? JSON.parse(user)?.user._id : '');
        const response2 = await getLink();

        if (isMounted) {
          setDashboard(response.data.data);
          setLink(response2.data?.data?.link);
          setAllowLogout(response?.data?.data?.user?.allowAutoLogout);
          setAutoLogout(response?.data?.data?.user?.autoLogout);
          setIsActive(response?.data?.data?.user?.isActive);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  const handleLogout = async (event) => {
    await Swal.fire({
      title: 'Are you sure?',
      // text: 'This action will logout your all users',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateLogout(JSON.parse(user)?.user._id, {
          autoLogout: !autoLogout,
        });

        // const response = await updateUser(JSON.parse(user)?.user._id, { autoLogout: !autoLogout });
        Swal.fire({
          title: 'updates!',
          text: 'Updated Successfully',
          icon: 'success',
        });
      }
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Cyberyard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 1 }}>
          Hi, {userObject?.adminFirstName} Welcome back
        </Typography>

        {loading ? (
          <CircularProgress size={20} />
        ) : (
          role === 'business' && (
            <Typography variant="inherit" sx={{ mb: 1 }}>
              {!isActive && (
                <div>
                  Your account is currently on Pause.
                  <br />
                  Please click here to re-subscribe to Cyberyard and continue to import great advertising videos
                </div>
              )}

              <a href={link} target="_blank" rel="noreferrer">
                {isActive ? 'Upgrade Account' : 'Re-subscribe here'}
              </a>
            </Typography>
          )
        )}
        {role === 'business' && allowLogout && (
          <FormControlLabel
            sx={{ mb: 5 }}
            control={<Checkbox checked={autoLogout} />}
            onChange={handleLogout}
            label="Logout all users automatically after 12 hours"
          />
        )}

        <Grid container spacing={1}>
          {role === 'admin' && (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <Link to="/dashboard/business-user" style={{ textDecoration: 'none' }}>
                  <AppWidgetSummary
                    title="Total Business"
                    total={dashboard.business}
                    icon={'mdi:user'}
                    loading={loading}
                  />
                </Link>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Total Users"
                  total={dashboard.users}
                  color="warning"
                  icon={'mdi:user'}
                  loading={loading}
                />
              </Grid> */}
            </>
          )}
          {role === 'business' && (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Maximum Users"
                  total={JSON.parse(user)?.user?.users}
                  icon={'mdi:user'}
                  loading={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Link to="/dashboard/user" style={{ textDecoration: 'none' }}>
                  <AppWidgetSummary title="Current Users" total={dashboard.users} icon={'mdi:user'} loading={loading} />
                </Link>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Link to="/dashboard/category" style={{ textDecoration: 'none' }}>
                  <AppWidgetSummary
                    title="Total Videos"
                    total={dashboard.videos}
                    color="warning"
                    icon={'mdi:payment'}
                    loading={loading}
                  />
                </Link>
              </Grid>
            </>
          )}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Albums" total={1352831} color="info" icon={'mdi:category'} />
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Notifications" total={234} color="error" icon={'mdi:bell'} />
          </Grid> */}
          {/* 
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="warning" icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="info" icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
