export const POST_LOGIN = '/auth/login/';

export const UPLOAD_FILE = '/upload/';
export const DELETE_FILE = '/upload-delete';

export const GET_DASHBOARD = '/user/dashboard';

export const CREATE_USERS = '/user/sign-up';
export const GET_USER = '/user/fetch';
export const GET_USERS = '/user/fetch-users';
export const CHANGE_PASSWORD = '/user/change-password';
export const UPDATE_USER = '/user/update-user';
export const UPDATE_LOGOUT = '/user/update-logout';
export const DELETE_USER = '/user/delete-user';
export const UPDATE_BUSINESS = '/user/update-business';

export const CREATE_ALBUM = '/album';
export const UPDATE_ALBUM = '/album/update';
export const GET_ALBUMS = '/album/fetch';

export const CREATE_VIDEO = '/video';
export const GET_VIDEO = '/video/fetch';
export const DELETE_VIDEO = '/video/delete';
export const GET_EXISTING_VIDEOS = '/video/exist';

export const GET_CATEGORIES = '/categories';

export const CREATE_LINK = '/upgrade';
export const GET_LINK = '/upgrade/fetch';
