import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Button, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { IoMdCloseCircleOutline } from 'react-icons/io';

// components
import Swal from 'sweetalert2';
import ReactPlayer from 'react-player';
import palette from '../../../theme/palette';
import {
  createAlbum,
  createVideo,
  deleteFile,
  deleteVideo,
  postJwtLogin,
  updateAlbum,
  uploadFile,
} from '../../../helper/backend_helper';
import Iconify from '../../../components/iconify';
import appConfig from '../../../config';

// ----------------------------------------------------------------------

export default function EditAlbumForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const album = location.state.album;

  const [showPassword, setShowPassword] = useState(false);
  const [oldVideos, setOldVideos] = useState(album?.videos ?? []);
  const [videosToRemove, setVideosToRemove] = useState([]);

  const [videos, setVideos] = useState([]);
  const [videosPreview, setVideosPreview] = useState([]);

  const [uploaded, setUploaded] = useState(0);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Album name is required'),
    secretKey: Yup.string().required('Secret Key is required'),
  });

  const handeLogin = async (values) => {
    if (!values?.name || !values?.secretKey) return null;

    if (videos?.length <= 0 && oldVideos.length <= 0) {
      return Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Atleast one video is required',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    try {
      setLoading(true);
      const response = await updateAlbum({ id: album?._id, name: values?.name });

      if (videosToRemove.length > 0) {
        for (let i = 0; i < videosToRemove.length; i += 1) {
          /* eslint-disable no-await-in-loop */

          const video = videosToRemove[i];
          console.log(video);
          await deleteFile({ url: `public/user/${video?.name}` });

          await deleteVideo({ id: video?._id });
        }
      }

      if (videos.length > 0) {
        for (let i = 0; i < videos.length; i += 1) {
          /* eslint-disable no-await-in-loop */

          const video = videos[i];
          const uploadFileheaders = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };

          const formData = new FormData();
          formData.append('file', video);
          const fileResponse = await uploadFile(formData, uploadFileheaders);

          const data = {
            name: fileResponse.data.file.filename,
            size: fileResponse.data.file.size,
            album: response.data.data._id,
          };

          await createVideo(data);
          setUploaded(uploaded + 1);
        }
      }

      setLoading(false);
      navigate('/dashboard/category', { replace: true });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status >= 400) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: error.response.data.errors[0].detail,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      name: album?.name,
      secretKey: album?.secretKey,
    },
    validationSchema,
    onSubmit: (values) => handeLogin(values),
  });

  const handleDeleteVideo = (indexToRemove) => {
    setVideosPreview(videosPreview.filter((_, index) => index !== indexToRemove));
    setVideos(videos.filter((_, index) => index !== indexToRemove));
  };

  const handleDeleteExistingVideo = (item) => {
    const updatedVideos = oldVideos.filter((video) => video._id !== item._id);

    setOldVideos(updatedVideos);

    setVideosToRemove([...videosToRemove, item]);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          label="Album Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
        />

        <TextField
          label="Secret Key"
          name="secretKey"
          value={formik.values.secretKey}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.secretKey && !!formik.errors.secretKey}
          helperText={formik.touched.secretKey && formik.errors.secretKey}
          disabled
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      {/* <Grid container>
        {videosPreview.length > 0 &&
          videosPreview.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} sx={{ mr: 10, mt: 5 }}>
              <div style={{ position: 'relative', width: 200, height: 150 }}>
                <ReactPlayer url={item} width="100%" height="100%" controls />

                <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => handleDeleteVideo(index)}>
                  <IoMdCloseCircleOutline />
                </IconButton>
              </div>
            </Grid>
          ))}
      </Grid> */}

      <Grid container>
        {/* Display existing videos */}
        {oldVideos &&
          oldVideos.map((video, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} sx={{ mr: 10, mt: 5 }}>
              <div style={{ position: 'relative', width: 200, height: 150 }}>
                {/* ReactPlayer component */}
                <ReactPlayer url={`${appConfig.FILe_URL}/${video.name}`} width="100%" height="100%" controls />
                {/* Cross button to remove existing video */}
                <IconButton
                  style={{ position: 'absolute', top: 0, right: 0 }}
                  onClick={() => handleDeleteExistingVideo(video)}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </div>
            </Grid>
          ))}
        {/* Display newly added videos */}
        {videosPreview.length > 0 &&
          videosPreview.map((item, index) => (
            <Grid item key={index + album.videos.length} xs={12} sm={6} md={4} sx={{ mr: 10, mt: 5 }}>
              <div style={{ position: 'relative', width: 200, height: 150 }}>
                {/* ReactPlayer component */}
                <ReactPlayer url={item} width="100%" height="100%" controls />
                {/* Cross button to remove newly added video */}
                <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => handleDeleteVideo(index)}>
                  <IoMdCloseCircleOutline />
                </IconButton>
              </div>
            </Grid>
          ))}
      </Grid>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
      <Button variant="outlined" size="large" component="label" sx={{ width: 200, color: 'black' }}>
        UPLOAD VIDEOS
        <input
          type="file"
          hidden
          accept="video/*"
          value=""
          multiple
          onChange={(e) => {
            const files = e.target.files;
            const urls = [];
            const objects = [];

            for (let i = 0; i < files.length; i += 1) {
              const file = files[i];
              urls.push(URL.createObjectURL(file) || null);
              objects.push(file || null);
            }

            setVideosPreview([...videosPreview, ...urls]);
            setVideos([...videos, ...objects]);
          }}
        />
      </Button>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
      {loading && `${uploaded} / ${videos.length}`}

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
      <LoadingButton
        sx={{
          backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
          color: 'black',
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handeLogin}
        loading={loading}
      >
        Update
      </LoadingButton>
    </form>
  );
}
