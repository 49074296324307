import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';

import palette from '../theme/palette';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { deleteUser, getUser, getUsers, updateUser } from '../helper/backend_helper';
import appConfig from '../config';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: 'name', label: 'Name', alignRight: false },
  { id: 'company', label: 'Email', alignRight: false },
  // { id: 'role', label: 'Mobile Number', alignRight: false },
  { id: 'isVerified', label: 'QR CODE', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'status', label: 'Last Login', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const user = localStorage.getItem('cyberyard');
const role = user && JSON.parse(user)?.user?.role?.name;

export default function UserPage() {
  const location = useLocation();
  const business = location.state?.user;
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [User, setUser] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getUsers('user', business ? business?._id : JSON.parse(user)?.user._id);
        const response2 = await getUser(JSON.parse(user).user._id);

        if (isMounted) {
          setUsers(response.data.data);
          setUser(response2.data?.data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  const handleDelete = async (user) => {
    await Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteUser(user?._id, {});

        const restUsers = users.filter((item) => item._id !== user?._id);
        setUsers([...restUsers]);

        Swal.fire({
          title: 'deleted!',
          text: 'User has been deleted',
          icon: 'success',
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      }
    });
  };

  const handleblock = async (user) => {
    await Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, block it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateUser(user?._id, { isActive: false });
        Swal.fire({
          title: 'blocked!',
          text: 'User has been blocked.',
          icon: 'success',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const handleActive = async (user) => {
    await Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Active it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateUser(user?._id, { isActive: true });

        Swal.fire({
          title: 'Activated!',
          text: 'User has been activated.',
          icon: 'success',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const handleLogout = async (user) => {
    await Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Logout it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateUser(user?._id, { deviceId: '', lastLogin: null });

        const findIndex = users.findIndex((item) => item._id === user?._id);
        users[findIndex].deviceId = '';
        users[findIndex].lastLogin = null;
        setUsers([...users]);

        Swal.fire({
          title: 'Logged Out!',
          text: 'User has been logged out.',
          icon: 'success',
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      }
    });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  let filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
  filteredUsers = [
    { role: { name: 'business' }, name: 'ads', email: business ? business?.email : JSON.parse(user)?.user.email },
    ...filteredUsers,
  ];
  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> User | Cyberyard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>

          {loading ? (
            <CircularProgress size={20} />
          ) : !business && !User?.isActive ? (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              sx={{
                backgroundImage: `linear-gradient(to bottom, #e0e4e8, #e0e4e8)`,
                boxShadow: 'none',
                color: 'gray',
              }}
              onClick={() => {
                Swal.fire({
                  width: 500,
                  title: 'Your account is currently on Pause',
                  text: 'Please re-subscribe to Cyberyard from your Dashboard and continue to import great advertising videos',
                  showConfirmButton: false,
                });
              }}
            >
              New User
            </Button>
          ) : (
            <Link to="/dashboard/user/create-user" state={{ user: business }}>
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                sx={{
                  backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
                  color: 'black',
                }}
              >
                New User
              </Button>
            </Link>
          )}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, QRCodeImage, name, email, phoneNumber, isActive, deviceId, directory } = row;

                    const currentDate = moment();
                    const providedDate = moment(row.lastLogin);
                    const diffInHours = currentDate.diff(providedDate, 'hours');

                    if (row.role.name !== 'admin') {
                      return (
                        <TableRow hover key={_id}>
                          <TableCell align="left">{email}</TableCell>

                          <TableCell align="left">
                            {row.role.name !== 'business' && (
                              <Avatar alt={name} src={`${appConfig.FILe_URL}/${QRCodeImage}`} variant="square" />
                            )}
                          </TableCell>

                          <TableCell align="left">
                            {row.role.name !== 'business' && (
                              <Label color={(diffInHours < 1 && row.lastLogin && 'success') || 'error'}>
                                {sentenceCase(diffInHours < 1 && row.lastLogin ? 'Logged In' : 'Not Logged In')}
                              </Label>
                            )}
                            {row.role.name === 'business' && <Label color="success">{sentenceCase('Admin')}</Label>}
                          </TableCell>

                          <TableCell align="left">
                            {row.role.name !== 'business' &&
                              diffInHours < 1 &&
                              row.lastLogin &&
                              new Date(row.lastLogin).toLocaleString()}
                          </TableCell>

                          <TableCell align="left">
                            {row.role.name !== 'business' && (
                              <div style={{ display: 'flex', gap: 20 }}>
                                <Link
                                  onClick={() => handleLogout(row)}
                                  style={{
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'blue',
                                  }}
                                >
                                  <Iconify icon={'eva:external-link-outline'} sx={{ mr: 1 }} />
                                  Logout
                                </Link>

                                <Link
                                  to="/dashboard/user/edit-user"
                                  state={{ user: row }}
                                  style={{
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'gray',
                                  }}
                                >
                                  <Iconify icon={'eva:edit-outline'} sx={{ mr: 1 }} />
                                  Edit
                                </Link>

                                <Link
                                  onClick={() => handleDelete(row)}
                                  style={{
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'red',
                                  }}
                                >
                                  <Iconify icon={'eva:close-circle-outline'} sx={{ mr: 1 }} />
                                  Delete
                                </Link>

                                {/* {role === 'business' && (
                                <div>
                                  {isActive ? (
                                    <Link
                                      onClick={() => handleblock(row)}
                                      style={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'red',
                                      }}
                                    >
                                      <Iconify icon={'eva:close-circle-outline'} sx={{ mr: 1 }} />
                                      Block
                                    </Link>
                                  ) : (
                                    <Link
                                      onClick={() => handleActive(row)}
                                      style={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'green',
                                      }}
                                    >
                                      <Iconify icon={'eva:checkmark-circle-2-outline'} sx={{ mr: 1 }} />
                                      Active
                                    </Link>
                                  )}
                                </div>
                              )} */}
                              </div>
                            )}

                            {/* <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton> */}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                  {loading && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={5}>
                        <CircularProgress size={20} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Link to="/dashboard/user/edit-user">
            <Iconify icon={'eva:edit-outline'} sx={{ mr: 2 }} />
            Edit
          </Link>
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Block
        </MenuItem>
      </Popover>
    </>
  );
}
