import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';

// components
import Swal from 'sweetalert2';
import palette from '../../../theme/palette';
import { createLink, createUser, getLink } from '../../../helper/backend_helper';

// ----------------------------------------------------------------------

export default function CreateLinkForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState('');
  console.log(link);
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getLink();
        if (isMounted) {
          setLink(response.data?.data?.link);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  const validationSchema = Yup.object().shape({
    link: Yup.string().required('Link is required'),
  });

  const handeLogin = async (values) => {
    if (!values?.link) {
      return null;
    }

    try {
      setLoading(true);

      console.log(values);
      const response = await createLink(values);

      setLoading(false);
      // navigate('/dashboard/business-user', { replace: true });

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Sucessfully Updated',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      setLoading(false);
      if (error?.response?.status >= 400) {
        return Swal.fire({
          position: 'center',
          icon: 'error',
          title: error.response.data.errors[0].detail,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      link,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handeLogin(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          label="Website Link"
          name="link"
          value={formik.values.link}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.link && !!formik.errors.link}
          helperText={formik.touched.link && formik.errors.link}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <LoadingButton
        sx={{
          backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
          color: 'black',
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handeLogin}
        loading={loading}
      >
        Add
      </LoadingButton>
    </form>
  );
}
