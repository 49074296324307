import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';

// components
import Swal from 'sweetalert2';
import palette from '../../../theme/palette';
import {
  changePasswordUser,
  createUser,
  deleteFile,
  postJwtLogin,
  updateUser,
  uploadFile,
} from '../../../helper/backend_helper';
import Iconify from '../../../components/iconify';
import appConfig from '../../../config';

// ----------------------------------------------------------------------

export default function EditUserFrom() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state.user;

  const [showPassword, setShowPassword] = useState(false);
  const [oldImage, setOldImage] = useState(user?.QRCodeImage);
  const [image, setImage] = useState(null);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').notRequired(),
    heading: Yup.string().max(30).notRequired('Heading is required'),
    description: Yup.string().max(60).notRequired('Description is required'),
  });

  const handeLogin = async (values) => {
    if (!values?.email) {
      return null;
    }

    try {
      setLoading(true);

      if (values?.password) {
        await changePasswordUser({ userId: user?._id, password: values?.password });
      }

      // if (!image) {
      //   setLoading(false);
      //   return navigate('/dashboard/user', { replace: true });
      // }

      if (image) {
        await deleteFile({ url: `public/user/${user?.QRCodeImage}` });

        const uploadFileheaders = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        const formData = new FormData();
        formData.append('file', image);
        const fileResponse = await uploadFile(formData, uploadFileheaders);
        values.QRCodeImage = fileResponse.data.file.filename;
      }

      const response = await updateUser(user?._id, {
        email: values.email,
        QRCodeImage: values.QRCodeImage,
        heading: values.heading,
        description: values.description,
      });

      setLoading(false);

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User updated successfully',
        showConfirmButton: false,
      });

      // navigate('/dashboard/user', { replace: true });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.status >= 400) {
        return Swal.fire({
          position: 'center',
          icon: 'error',
          title: error.response.data.errors[0].detail,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      email: user?.email,
      heading: user?.heading,
      description: user?.description,
    },
    validationSchema,
    onSubmit: (values) => handeLogin(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} gap={2}>
        <Stack spacing={3}>
          <TextField
            sx={{ width: 300 }}
            label="Email address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            disabled
          />

          <TextField
            name="password"
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
          />

          <TextField
            fullWidth
            label="Heading"
            name="heading"
            value={formik.values.heading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.heading && !!formik.errors.heading}
            helperText={formik.touched.heading && formik.errors.heading}
          />

          <TextField
            fullWidth
            label="Description"
            name="description"
            rows={5}
            multiline
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
          />

          <Button variant="outlined" size="large" component="label" sx={{ width: 200, color: 'black' }}>
            UPLOAD QR IMAGE
            <input type="file" hidden accept="image/*" value="" onChange={(e) => setImage(e.target.files[0] || null)} />
          </Button>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

          <LoadingButton
            sx={{
              backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
              color: 'black',
            }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handeLogin}
            loading={loading}
          >
            Update
          </LoadingButton>
        </Stack>

        <Stack spacing={3} textAlign="center" alignItems="center">
          <Typography variant="subtitle1" gutterBottom>
            {formik.values.heading}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ whiteSpace: 'pre-line' }}>
            {formik.values.description}
          </Typography>

          {image && (
            <img
              src={URL.createObjectURL(image)}
              className="cover"
              style={{
                objectFit: 'cover',
                width: '150px',
                height: '150px',
                borderRadius: 10,
              }}
              alt="QR CODE"
            />
          )}

          {!image && oldImage && (
            <img
              src={`${appConfig.FILe_URL}/${oldImage}`}
              className="cover"
              style={{
                objectFit: 'cover',
                width: '150px',
                height: '150px',
                borderRadius: 10,
              }}
              alt="QR CODE"
            />
          )}
        </Stack>
      </Stack>
    </form>
  );
}
