import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { Link, useLocation } from 'react-router-dom';

import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
} from '@mui/material';
import ReactPlayer from 'react-player';
import Swal from 'sweetalert2';

import palette from '../theme/palette';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { deleteFile, deleteVideo, getAlbums, getCategories, getUser, getVideos } from '../helper/backend_helper';
import appConfig from '../config';

// mock
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: '', label: 'Name', alignRight: false },
  { id: '', label: 'Name', alignRight: false },
  // { id: '', label: 'Videos', alignRight: false },
  { id: '', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const user = localStorage.getItem('cyberyard');
const role = user && JSON.parse(user)?.user?.role?.name;

export default function UserPage() {
  const location = useLocation();
  const business = location.state?.user;

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const [User, setUser] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getVideos(business ? business?._id : JSON.parse(user).user._id);
        const response2 = await getUser(JSON.parse(user).user._id);

        if (isMounted) {
          setCategories(response.data.data);
          setUser(response2.data?.data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0;

  const filteredUsers = applySortFilter(categories, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleDeleteExistingVideo = async (id, name) => {
    Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteFile({ url: `public/user/${name}` });

        await deleteVideo({ id });

        const restVideos = categories.filter((item) => item._id !== id);
        setCategories([...restVideos]);

        Swal.fire({
          title: 'Deleted!',
          text: 'Video has been deleted.',
          icon: 'success',
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title> Videos | Cyberyard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Videos
          </Typography>

          {loading ? (
            <CircularProgress size={20} />
          ) : !business && !User?.isActive ? (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              sx={{
                backgroundImage: `linear-gradient(to bottom, #e0e4e8, #e0e4e8)`,
                boxShadow: 'none',
                color: 'gray',
              }}
              onClick={() => {
                Swal.fire({
                  width: 500,
                  title: 'Your account is currently on Pause',
                  text: 'Please re-subscribe to Cyberyard from your Dashboard and continue to import great advertising videos',
                  showConfirmButton: false,
                });
              }}
            >
              New Video
            </Button>
          ) : (
            <Link to="/dashboard/category/create-album" state={{ user: business }}>
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                sx={{
                  backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
                  color: 'black',
                }}
              >
                New Video
              </Button>
            </Link>
          )}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={categories.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, originalname, name, secretKey, videos } = row;

                    return (
                      <TableRow hover key={_id}>
                        {/* <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={title} src={`${appConfig.FILe_URL}${directory}/${image}`} variant="rounded" />
                          </Stack>
                        </TableCell> */}

                        <TableCell align="left" width={200}>
                          <div style={{ position: 'relative', width: 150, height: 100 }}>
                            <ReactPlayer url={`${appConfig.FILe_URL}/${name}`} width="100%" height="100%" controls />
                          </div>
                        </TableCell>

                        <TableCell align="left" width={600}>
                          <Typography variant="subtitle2">
                            {/* {name.slice(0, 20)}
                            ...{name.slice(-10)} */}
                            {originalname}
                          </Typography>
                        </TableCell>
                        {/* <TableCell align="left">{secretKey}</TableCell> */}

                        <TableCell align="left">
                          <Box
                            style={{
                              textDecoration: 'none',
                              display: 'flex',
                              alignItems: 'center',
                              color: 'red',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleDeleteExistingVideo(_id, name)}
                          >
                            <Iconify icon={'eva:trash-outline'} sx={{ mr: 1 }} />
                            Delete
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={categories.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Link to="/dashboard/category/sub-category" style={{ textDecoration: 'none', color: 'black' }}>
          <MenuItem>
            <Iconify icon={'eva:edit-outline'} sx={{ mr: 2 }} />
            Sub Categories
          </MenuItem>
        </Link>

        {/* <MenuItem>
          <Iconify icon={'mdi-document'} sx={{ mr: 2 }} />
          Manage Content
        </MenuItem> */}
      </Popover>
    </>
  );
}
