// const appConfig = {
//   API_URL: 'http://localhost:5013/api/v1',
//   FILe_URL: 'http://localhost:5013/public/user',
//   PDF_URL: 'http://localhost:5013/public/user',
// };

const appConfig = {
  API_URL: 'https://cyberyardapi.codecoytechnologies.live/api/v1',
  FILe_URL: 'https://cyberyardapi.codecoytechnologies.live/public/user',
  PDF_URL: 'https://cyberyardapi.codecoytechnologies.live/public/user',
};

export default appConfig;
