import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import { useLocation } from 'react-router-dom';

import useResponsive from '../hooks/useResponsive';
import CreateAlbumForm from '../sections/@dashboard/album/createAlbumForm';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  // justifyContent: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function CreateAlbumPage() {
  const mdUp = useResponsive('up', 'md');

  const location = useLocation();
  const business = location.state?.user;

  return (
    <>
      <Helmet>
        <title> Video | Cyberyard </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="xl">
          <StyledContent>
            <Typography variant="h5" gutterBottom mb={3}>
              Add New Videos
            </Typography>

            <br />

            <CreateAlbumForm business={business} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
