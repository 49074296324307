import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';

// components
import Swal from 'sweetalert2';
import palette from '../../../theme/palette';
import {
  changePasswordUser,
  createUser,
  deleteFile,
  postJwtLogin,
  updateBusiness,
  updateLogout,
  updateUser,
  uploadFile,
} from '../../../helper/backend_helper';
import Iconify from '../../../components/iconify';
import appConfig from '../../../config';

// ----------------------------------------------------------------------

export default function EditBusinessUserFrom() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state.user;

  const [showPassword, setShowPassword] = useState(false);
  const [oldImage, setOldImage] = useState(user?.QRCodeImage);
  const [image, setImage] = useState(null);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    adminFirstName: Yup.string().required('First name name is required'),
    adminLastName: Yup.string().required('Last name is required'),
    adminEmail: Yup.string().email('Invalid email').required('Email is required'),
    adminPhoneNumber: Yup.string().required('Phone Number is required'),
    name: Yup.string().required('Business name is required'),
    phoneNumber: Yup.string().required('Phone Number name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').notRequired(),
    users: Yup.number().positive('Number of users must be greater than 0').required('Number of users is required'),
  });

  const handeLogin = async (values) => {
    if (!values?.email) {
      return null;
    }

    try {
      setLoading(true);

      if (values?.password) {
        await changePasswordUser({ userId: user?._id, password: values?.password });
      }

      if (!values?.allowAutoLogout) {
        const response = await updateLogout(user?._id, {
          allowAutoLogout: false,
          autoLogout: false,
        });
      }

      // if (!image) {
      //   setLoading(false);
      //   return navigate('/dashboard/user', { replace: true });
      // }

      // if (image) {
      //   await deleteFile({ url: `public/user/${user?.QRCodeImage}` });

      //   const uploadFileheaders = {
      //     headers: {
      //       'content-type': 'multipart/form-data',
      //     },
      //   };

      //   const formData = new FormData();
      //   formData.append('file', image);
      //   const fileResponse = await uploadFile(formData, uploadFileheaders);
      //   values.QRCodeImage = fileResponse.data.file.filename;
      // }

      const response = await updateBusiness(user?._id, {
        adminFirstName: values?.adminFirstName,
        adminLastName: values?.adminLastName,
        adminEmail: values?.adminEmail,
        adminPhoneNumber: values?.adminPhoneNumber,
        name: values?.name,
        phoneNumber: values?.phoneNumber,
        email: values.email,
        users: values?.users,
        allowAutoLogout: values?.allowAutoLogout,
      });

      setLoading(false);
      navigate('/dashboard/business-user', { replace: true });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.status >= 400) {
        return Swal.fire({
          position: 'center',
          icon: 'error',
          title: error.response.data.errors[0].detail,
          showConfirmButton: false,
          // timer: 1500,
        });
      }
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      adminFirstName: user?.adminFirstName,
      adminLastName: user?.adminLastName,
      adminEmail: user?.adminEmail,
      adminPhoneNumber: user?.adminPhoneNumber,
      name: user?.name,
      phoneNumber: user?.phoneNumber,
      email: user?.email,
      password: '',
      users: user?.users,
      allowAutoLogout: user?.allowAutoLogout,
    },
    validationSchema,
    onSubmit: (values) => handeLogin(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <Stack gap={3} direction={{ md: 'row', sm: 'column' }}>
          <TextField
            fullWidth
            label="Admin First Name"
            name="adminFirstName"
            value={formik.values.adminFirstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.adminFirstName && !!formik.errors.adminFirstName}
            helperText={formik.touched.adminFirstName && formik.errors.adminFirstName}
          />
          <TextField
            fullWidth
            label="Admin Last Name"
            name="adminLastName"
            value={formik.values.adminLastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.adminLastName && !!formik.errors.adminLastName}
            helperText={formik.touched.adminLastName && formik.errors.adminLastName}
          />
        </Stack>

        <TextField
          label="Admin Email"
          name="adminEmail"
          value={formik.values.adminEmail}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.adminEmail && !!formik.errors.adminEmail}
          helperText={formik.touched.adminEmail && formik.errors.adminEmail}
        />
        <TextField
          label="Admin Phone Number"
          name="adminPhoneNumber"
          value={formik.values.adminPhoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.adminPhoneNumber && !!formik.errors.adminPhoneNumber}
          helperText={formik.touched.adminPhoneNumber && formik.errors.adminPhoneNumber}
        />

        <Stack gap={3} direction={{ md: 'row', sm: 'column' }}>
          <TextField
            fullWidth
            label="Business Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
          />

          <TextField
            fullWidth
            label="Business Phone Number"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          />
        </Stack>

        <TextField
          label="Email address"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          disabled
        />

        <TextField
          name="password"
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && !!formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
        />

        <TextField
          label="Number of users"
          name="users"
          value={formik.values.users}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.users && !!formik.errors.users}
          helperText={formik.touched.users && formik.errors.users}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <FormControlLabel
        control={<Checkbox checked={formik.values.allowAutoLogout} />}
        label="Allow automatic logout users after 12 hours"
        name="allowAutoLogout"
        onChange={formik.handleChange}
      />
      {/* <FormHelperText sx={{ color: 'red' }}>Note: Remove access will logout all users for this business</FormHelperText> */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      {/* {image && (
        <img
          src={URL.createObjectURL(image)}
          className="cover"
          style={{
            objectFit: 'cover',
            width: '150px',
            height: '150px',
            borderRadius: 10,
          }}
          alt="QR CODE"
        />
      )}

      {!image && oldImage && (
        <img
          src={`${appConfig.FILe_URL}/${oldImage}`}
          className="cover"
          style={{
            objectFit: 'cover',
            width: '150px',
            height: '150px',
            borderRadius: 10,
          }}
          alt="QR CODE"
        />
      )}

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Button variant="outlined" size="large" component="label" sx={{ width: 200, color: 'black' }}>
        UPLOAD QR IMAGE
        <input type="file" hidden accept="image/*" value="" onChange={(e) => setImage(e.target.files[0] || null)} />
      </Button> */}

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <LoadingButton
        sx={{
          backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
          color: 'black',
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handeLogin}
        loading={loading}
      >
        Update
      </LoadingButton>
    </form>
  );
}
