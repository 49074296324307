import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';

// components
import Swal from 'sweetalert2';
import palette from '../../../theme/palette';
import { createUser, postJwtLogin, uploadFile } from '../../../helper/backend_helper';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const user = localStorage.getItem('cyberyard');
const role = user && JSON.parse(user)?.user?.role?.name;

export default function CreateUserForm({ business }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState(null);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    heading: Yup.string().max(30).notRequired('Heading is required'),
    description: Yup.string().max(60).notRequired('Description is required'),
  });

  const handeLogin = async (values) => {
    if (!values?.email || !values?.password) {
      return null;
    }

    try {
      setLoading(true);

      if (image) {
        const uploadFileheaders = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        const formData = new FormData();
        formData.append('file', image);
        const fileResponse = await uploadFile(formData, uploadFileheaders);
        values.QRCodeImage = fileResponse.data.file.filename;
      }

      const response = await createUser({
        ...values,
        business: business ? business?._id : JSON.parse(user)?.user._id,
        userRole: 'user',
      });

      setLoading(false);

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User added successfully',
        showConfirmButton: false,
        // timer: 1500,
      });

      // navigate('/dashboard/user', { replace: true });
    } catch (error) {
      setLoading(false);
      if (error?.response?.status >= 400) {
        return Swal.fire({
          position: 'center',
          icon: 'error',
          title: error.response.data.errors[0].detail,
          showConfirmButton: false,
          // timer: 1500,
        });
      }
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      heading: '',
      description: '',
    },
    validationSchema,
    onSubmit: (values) => handeLogin(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} gap={2}>
        <Stack spacing={3}>
          <TextField
            sx={{ width: 300 }}
            label="Email address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              autocomplete: 'new-password',
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
          />

          <TextField
            fullWidth
            label="Heading"
            name="heading"
            value={formik.values.heading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.heading && !!formik.errors.heading}
            helperText={formik.touched.heading && formik.errors.heading}
          />

          <TextField
            fullWidth
            label="Description"
            name="description"
            rows={5}
            multiline
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
          />

          <Button variant="outlined" size="large" component="label" sx={{ width: 200, color: 'black' }}>
            UPLOAD QR IMAGE
            <input type="file" hidden accept="image/*" value="" onChange={(e) => setImage(e.target.files[0] || null)} />
          </Button>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

          <LoadingButton
            sx={{
              backgroundImage: `linear-gradient(to bottom, ${palette.primary.main}, ${palette.primary.main2})`,
              color: 'black',
            }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handeLogin}
            loading={loading}
          >
            Create
          </LoadingButton>
        </Stack>

        <Stack spacing={3} textAlign="center" alignItems="center">
          <Typography variant="subtitle1" gutterBottom>
            {formik.values.heading}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ whiteSpace: 'pre-line' }}>
            {formik.values.description}
          </Typography>

          {image && (
            <img
              src={URL.createObjectURL(image)}
              className="cover"
              style={{
                objectFit: 'cover',
                width: '150px',
                height: '150px',
                borderRadius: 10,
              }}
              alt="QR CODE"
            />
          )}
        </Stack>
      </Stack>
    </form>
  );
}
